import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import PageNotFound from './pages/404';
import HomePage from './pages/home';


ReactDOM.createRoot( document.getElementById('root') as HTMLElement )
.render(
  <BrowserRouter >
    <Routes>
      <Route path="" element={<HomePage/>} />
      <Route path="*" element={<PageNotFound/>} />
    </Routes>
  </BrowserRouter>
);