import { useCallback } from "react"
import { Link } from "react-router-dom"
import { BsArrowRight, BsTwitter, BsInstagram, BsFacebook } from "react-icons/bs"
import Countdown from "react-countdown-simple"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import 'react-circular-progressbar/dist/styles.css';

export default function HomePage(){

    const SubscribeButton = useCallback( () => (
        <Link
            target='_blank' 
            to='https://docs.google.com/forms/d/e/1FAIpQLSfzJnVA6bzxUA3HN_fvpAFsbn3ogkXVhcYbci23_ElidJ0Q3g/viewform?usp=sf_link'
            className='text-lg w-fit flex justify-center items-center text-black gap-x-2 bg-primary hover:bg-black hover:text-primary rounded-3xl px-10 py-3'
        >
            S'inscrire
            <BsArrowRight className="" />
        </Link>
    ), [])     

    const CircularProgress = useCallback( ({max= 100, value= 0, mainText, subText}: {max?: number, value?: number, mainText?: string, subText?: string}) => (
        <>
            <div className="relative w-32 md:w-44 h-32 md:h-44">
                <div className="flex flex-col items-center justify-center absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 w-[90%] h-[90%] rounded-full bg-white">
                    {!mainText ? `${value}%` : <h3 className="text-4xl lg:text-7xl font-semibold">{mainText}</h3>}
                    {subText && <span className="uppercase lg:text-xl font-thin">{subText}</span>}
                </div>
                <CircularProgressbar strokeWidth={5} styles={buildStyles({pathColor: `#F1BA4F`,trailColor: '#ECEBEB',})} value={value}/>
            </div>
            {/* <div className="flex justify-center items-center relative h-32 lg:h-44 w-32 lg:w-44 rounded-full bg-gray overflow-hidden">
                <div className="absolute right-0 h-full bg-primary rounded-sm" style={{ width: `${value}%` }} />
                <div className="flex flex-col items-center justify-center relative z-10 w-[90%] h-[90%] rounded-full bg-white">
                    {!mainText ? `${value}%` : <h3 className="text-4xl lg:text-7xl font-semibold">{mainText}</h3>}
                    {subText && <span className="uppercase lg:text-xl font-thin">{subText}</span>}
                </div>
            </div> */}
        </>
    ), [])


    return( <>
        <header className='bg-white py-2'>
            <div className="container flex items-center justify-between">
                <div className="flex items-center gap-12">
                    <img className='w-20 md:w-24' src='/images/we-act-logo.svg' alt='logo rawbank' />
                    <img className='w-20 md:w-24' src='/images/we-act-rawbank.svg' alt='logo rawbank' />
                    <img className='w-20 md:w-24' src='/images/we-act-kadea.svg' alt='logo rawbank' />
                </div>
                <div className="max-sm:hidden">
                    <SubscribeButton />
                </div>
            </div>
        </header>
        <section className='relative flex bg-secondary overflow-hidden'>
            <img src='/images/we-act-bg.svg' className='absolute inset-0 w-full h-full object-cover' alt='bg' />
            <div className=" drop-shadow-2xl relative z-10 container flex">
                <div className='relative z-10 pt-10 pb-44 md:max-w-sm lg:max-w-2xl flex flex-col gap-y-6 text-white'>
                    <h1 className=" max-md:text-center text-4xl lg:text-[4rem] lg:leading-none font-doublePlus">
                        <span className='text-primary'>Boostez votre <br className="max-md:hidden" /> PME</span>&nbsp;
                        avec une <br className="max-md:hidden" /> solution numérique offerte
                    </h1>
                    <img src='/images/we-act-hero.png' className='object-cover  md:hidden' alt='' />
                    <p className=' max-md:text-center  font-normal text-lg lg:text-xl'>
                        Vous êtes une PME ? Inscrivez vous au projet We act et bénéficiez d'un accompagnement gratuit pour la réalisation de vos projets numériques (site web, application mobile, logiciel de gestion, stratégie Marketing...) développés par les apprenants de Kadea Academy.
                    </p>
                    <div className="py-4 lg:py-8 flex max-md:justify-center">
                        <SubscribeButton />
                    </div>
                </div>
                <div className=' max-md:hidden self-end'>
                    <img src='/images/we-act-hero.png' className='max-xmd:scale-150 lg:scale-[2] xl:scale-125 2xl:scale-100 md:-translate-y-20 md:-translate-x-20 xmd:-translate-y-0 xmd:-translate-x-0 lg:-translate-x-36 lg:-translate-y-1/2 xl:-translate-x-14 xl:-translate-y-16 2xl:translate-y-0 2xl:translate-x-0' alt='' />
                </div>
            </div>
        </section>
        <section className='relative z-10 max-lg:-translate-y-32 -translate-y-1/2'>
            <div className='container flex max-lg:flex-wrap max-lg:gap-10 justify-between'>
                <div className=" max-lg:mx-auto w-full md:w-[47%] lg:w-[32%] text-center flex flex-col gap-y-4 items-center rounded-[1.25rem] max-w-lg:px-6 p-8 bg-primary">
                    <img className="object-contain w-20 h-20" src="/images/icons/we-act-inscription.svg" alt="" />
                    <h3 className='  text-2xl font-normal'>Inscription</h3>
                    <p className='text-xl'>
                        Donnez à votre entreprise l’opportunité de bénéficier d’un accompagnement gratuit en transformation digitale 
                    </p>
                </div>
                <div className=" max-lg:mx-auto w-full md:w-[47%] lg:w-[32%] text-center flex flex-col gap-y-4 items-center rounded-[1.25rem] max-w-lg:px-6 p-8 bg-black text-primary">
                    <img className="object-contain w-20 h-20" src="/images/icons/we-act-selection.svg" alt="" />
                    <h3 className='  text-2xl font-normal'>Séléction</h3>
                    <p className='text-xl'>
                        15 PME seront sélectionnés dont 9 PME à Kinshasa, 3 à Lubumbashi et 3 à Goma 
                    </p>
                </div>
                <div className=" max-lg:mx-auto w-full md:w-[47%] lg:w-[32%] text-center flex flex-col gap-y-4 items-center rounded-[1.25rem] max-w-lg:px-6 p-8 bg-primary">
                    <img className="object-contain w-20 h-20" src="/images/icons/we-act-accompagnement.svg" alt="" />
                    <h3 className='  text-2xl font-normal'>Accompagnement</h3>
                    <p className='text-xl'>
                        On identifie les besoins de l’entreprise ensuite les apprenants développent la solution, le tout en 6 mois
                    </p>
                </div>
            </div>
        </section>
        <section className="-mt-14 pb-14 flex justify-center">
            <SubscribeButton/>
        </section>
        <section className="pb-16">
            <div className="container flex flex-col items-center gap-y-10">
                <div className="flex flex-col gap-y-2">
                    <h3 className="text-center text-2xl lg:font-bold text-primary">Compte à rébours</h3>
                    <h2 className="text-center text-3xl lg:text-5xl font-semibold lg:font-bold">Fin des inscriptions</h2>
                </div>
                <Countdown targetDate="2023-09-29T17:00:24.373Z" renderer={({ days, hours, minutes, seconds }: any) => (
                <div className="grid grid-cols-2 md:grid-cols-4 justify-center gap-4">
                    <CircularProgress value={100 / 60 * days} mainText={days} subText="jours" />
                    <CircularProgress value={100 / 60 * hours} mainText={hours < 10 ? `0${hours}`: hours} subText="heures" />
                    <CircularProgress value={100 / 60 * minutes} mainText={minutes < 10 ? `0${minutes}`: minutes} subText="minutes" />
                    <CircularProgress value={100 / 60 * seconds} mainText={seconds < 10 ? `0${seconds}`: seconds} subText="secondes" />                    
                    
                </div>

                )}/>
            </div>
        </section>
        <section className="bg-black py-16 text-primary">
            <div className="container flex flex-col gap-y-8">
                <p className="text-sm md:text-lg text-center max-w-3xl mx-auto">
                    {/* Développé par la Rawbank, en partenariat avec Kadea Academy, We act est un projet d’accompagnement de votre PME dans sa transformation Digitale, on associe des apprenants et une entreprise, on identifie le besoin de cette dernière ensuite les apprenants développent la solution, le tout en 6 mois Chaque entreprise sera suivie par un chef de projet afin d’assurer la gestion du projet. */}
                    Développé par la Rawbank, en partenariat avec Kadea Academy, We act est un projet d’accompagnement des PME dans leurs transformation Digitale, le projet  associe des apprenants de la Kadea Acadmy à une entreprise afin d’identifier le besoin de cette dernière, les apprenants développent la solution en seulement 6 mois et chaque entreprise sera suivie par un chef de projet afin d’assurer sa gestion.
                </p>
                <div className="flex gap-x-4 justify-center text-2xl">                    
                    <Link target="_blank" to="https://twitter.com/Rawbank_sa?t=-6KMjPkEfHKVZszEz-pdfw&s=09">
                        <BsTwitter className="hover:text-white"/>
                    </Link>
                    <Link target="_blank" to="https://instagram.com/rawbanksa?igshid=MzRlODBiNWFlZA==">
                        <BsInstagram className="hover:text-white"/>
                    </Link>
                    <Link target="_blank" to="https://www.facebook.com/RawbankSa?mibextid=D4KYlr">
                        <BsFacebook className="hover:text-white"/>
                    </Link>
                </div>
            </div>
        </section>
    </>)
}