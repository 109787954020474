import { Link } from "react-router-dom";
import { FcHome } from "react-icons/fc"

export default function PageNotFound(){

    return( 
        <div className='bg-gray h-screen flex flex-col gap-5 items-center justify-center'>
            <h1 className="font-semibold text-7xl text-primary/90">
                Oops!
            </h1>
            <p className='max-w-xl text-lg md:text-xl'>
                La page que tu recherche n&apos;exite pas!
            </p>
            <p>
            <Link to="/" className='hover:bg-primary hover:text-white flex items-center gap-x-2 px-6 py-2 border-2 border-primary rounded-lg'>
                <FcHome className='text-xl' />
                Accueil
            </Link>
            </p>
        </div>
    )
}